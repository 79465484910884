var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',{staticClass:"mt-n3 rounded-0"},[_c('v-card-title',[_c('button-tip',{attrs:{"xsmall":"","fab":"","icon":"","icolor":"black","iname":"mdi-update","elevation":"5","tip":"Refresh Claims"},on:{"click":function($event){return _vm.getClaimsForThisFirm(_vm.firm.fid)}}}),_c('v-spacer'),_c('v-text-field',{staticClass:"mt-n6 mb-n2",attrs:{"append-icon":"mdi-magnify","clearable":"","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-2 left",class:[!_vm.notPopup ? 'popupLargeTable' : 'largeSizeTable'],style:(_vm.$_cssVars),attrs:{"dense":"","fixed-header":"","headers":_vm.colHeaders,"items":_vm.claims,"loading":_vm.loading,"loading-text":"Data loading... Please wait...","mobile-breakpoint":0,"no-data-text":"There is no claims data available for this firm.","search":_vm.search,"sort-by":"clid","footer-props":{ 'items-per-page-options': [ 5, 10, 25, 50, 100] }},scopedSlots:_vm._u([(_vm.rightsArray.includes('edit-claim'))?{key:"item.actions",fn:function({ item }){return [_c('button-tip',{attrs:{"icon":"","iname":"mdi-pencil","tip":"Edit Popup"},on:{"click":function($event){return _vm.editClaim(item)}}}),_c('button-tip',{attrs:{"icon":"","iname":"mdi-open-in-new","tip":"Print/View Popup"},on:{"click":function($event){return _vm.viewClaim(item)}}})]}}:null,_vm._l((_vm.fields),function(field){return {key:`item.${field}`,fn:function(props){return [_c('v-edit-dialog',{staticStyle:{"position":"relative","left":"20px"},attrs:{"return-value":field === 'claim' ? '' : props.item[field]},scopedSlots:_vm._u([{key:"input",fn:function(){return [((field !== 'clid') && (field !== 'id') && (field !== 'claim')
                          && (_vm.amountFieldsReadonly.indexOf(field) === -1 )
                          && (_vm.rightsArray.includes('edit-claim')))?_c('v-text-field',{staticClass:"mx-2",on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.save(props.item)}},model:{value:(props.item[field]),callback:function ($$v) {_vm.$set(props.item, field, $$v)},expression:"props.item[field]"}}):_vm._e(),(field === 'claim' && _vm.showDatePicker
                         && _vm.rightsArray.includes('edit-claim'))?_c('v-text-field',{staticClass:"px-3 mb-n5",attrs:{"clearable":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.save(props.item)},"click:clear":function($event){props.item[field] = '', _vm.save(props.item)}},model:{value:(props.item.noDate === true ? '' :
                          _vm.moment(props.item.claim, 'YYYY-MM-DD').format('DD MMM YYYY')),callback:function ($$v) {_vm.$set(props.item.noDate === true ? '' :
                          _vm.moment(props.item.claim, 'YYYY-MM-DD'), "format('DD MMM YYYY')", $$v)},expression:"props.item.noDate === true ? '' :\n                          moment(props.item.claim, 'YYYY-MM-DD').format('DD MMM YYYY')"}}):_vm._e(),(field === 'claim' && _vm.showDatePicker
                          && _vm.rightsArray.includes('edit-claim'))?_c('v-date-picker',{staticClass:"ml-n2",attrs:{"no-title":""},on:{"input":function($event){return _vm.save(props.item)}},model:{value:(props.item.claim),callback:function ($$v) {_vm.$set(props.item, "claim", $$v)},expression:"props.item.claim"}}):_vm._e()]},proxy:true}],null,true)},[((props.item[field] && (_vm.amountFieldsReadonly.indexOf(`${field}`) !== -1
           || _vm.amountFieldsEditable.indexOf(field) !== -1)))?_c('div',[_vm._v(" $"+_vm._s((parseFloat(props.item[`${field}`])).toFixed(2).toString() .replace(/\d(?=(\d{3})+\.)/g, '$&,'))+" ")]):(field === 'claim')?_c('div',{on:{"click":function($event){_vm.showDatePicker = true}}},[_vm._v(" "+_vm._s(props.item.noDate === true ? '____________ ' : _vm.moment(props.item.claim, 'YYYY-MM-DD').format('DD MMM YYYY'))+" ")]):_c('div',[_vm._v(" "+_vm._s(props.item[field])+" ")])])]}}})],null,true)})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }