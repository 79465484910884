<template>
  <div>
    <v-card class="mt-n3 rounded-0">
      <v-card-title>
        <button-tip xsmall fab icon icolor="black" iname="mdi-update"
                    elevation="5" tip="Refresh Claims" @click="getClaimsForThisFirm(firm.fid)"/>
        <v-spacer/>
        <v-text-field v-model="search" append-icon="mdi-magnify" clearable label="Search"
                      single-line hide-details class="mt-n6 mb-n2"></v-text-field>
      </v-card-title>
    </v-card>
    <v-data-table class="elevation-2 left"
                  :class="[!notPopup ? 'popupLargeTable' : 'largeSizeTable']"
                  dense
                  fixed-header
                  :headers="colHeaders"
                  :items="claims"
                  :loading="loading"
                  loading-text="Data loading... Please wait..."
                  :mobile-breakpoint="0"
                  no-data-text="There is no claims data available for this firm."
                  :search="search"
                  sort-by="clid"
                  :style="$_cssVars"
                  :footer-props="{ 'items-per-page-options': [ 5, 10, 25, 50, 100] }">
      <template v-slot:item.actions="{ item }" v-if="rightsArray.includes('edit-claim')">
        <button-tip @click="editClaim(item)" icon iname="mdi-pencil" tip="Edit Popup"/>
        <button-tip @click="viewClaim(item)" icon iname="mdi-open-in-new" tip="Print/View Popup"/>
      </template>
      <template v-for="field in fields" v-slot:[`item.${field}`]="props">
        <v-edit-dialog style="position: relative; left: 20px;" :return-value="field === 'claim' ? '' : props.item[field]">
          <div v-if="(props.item[field] && (amountFieldsReadonly.indexOf(`${field}`) !== -1
             || amountFieldsEditable.indexOf(field) !== -1))">
            ${{ (parseFloat(props.item[`${field}`])).toFixed(2).toString()
            .replace(/\d(?=(\d{3})+\.)/g, '$&,') }}
          </div>
          <div v-else-if="field === 'claim'" @click="showDatePicker = true">
            {{ props.item.noDate === true ? '____________ ' :
              moment(props.item.claim, 'YYYY-MM-DD').format('DD MMM YYYY') }}
          </div>
          <div v-else>
            {{ props.item[field] }}
          </div>
          <template v-slot:input>
            <v-text-field class="mx-2" @keyup.enter="save(props.item)"
                          v-model="props.item[field]"
                          v-if="(field !== 'clid') && (field !== 'id') && (field !== 'claim')
                            && (amountFieldsReadonly.indexOf(field) === -1 )
                            && (rightsArray.includes('edit-claim'))"
            ></v-text-field>
            <v-text-field v-if="field === 'claim' && showDatePicker
                           && rightsArray.includes('edit-claim')"
                          class="px-3 mb-n5" @keyup.enter="save(props.item)"
                          @click:clear="props.item[field] = '', save(props.item)" clearable
                          v-model="props.item.noDate === true ? '' :
                            moment(props.item.claim, 'YYYY-MM-DD').format('DD MMM YYYY')"
            ></v-text-field>
            <v-date-picker no-title v-if="field === 'claim' && showDatePicker
                            && rightsArray.includes('edit-claim')"
                           @input="save(props.item)"
                           v-model="props.item.claim"
                           class="ml-n2">
            </v-date-picker>
          </template>
        </v-edit-dialog>
      </template>

    </v-data-table>
  </div>
</template>

<script>
  import moment from 'moment';
  import { FirmsAPIService } from '@/servicehandlers/FirmsAPIService';
  import { ClaimsAPIService } from "@/servicehandlers/ClaimsAPIService";

  const apiService = new FirmsAPIService();
  const claimAPIService = new ClaimsAPIService();

  export default {
    name: "FirmClaimsTable",
    props: {
      firm: Object,
      rightsArray: Array,
      notPopup: Boolean,
      default() {
        return {};
      }
    },
    data() {
      return {
        showDatePicker: false,
        errors: [],
        loading:false,
        search: '',
        claims: [],
        amountFieldsEditable: ['damages', 'defense'],
        amountFieldsReadonly: ['total_remaining_reserves', 'total_indemnity',
          'total_defense', 'total_fkb', 'total_incurred'],
        fields: ['id', 'claimant', 'claim', 'type', 'status', 'damages', 'defense',
          'total_remaining_reserves', 'total_indemnity', 'total_defense', 'total_fkb',
          'total_incurred'],
        colHeaders: [ // for claims
          {value: 'actions', text: 'Actions',   width: "105px", align: 'center'},
          {value: 'clid',    text: 'Claim ID',  width: "100px", align: 'end'},
          {value: 'claimant',text: 'Claimant',  width: "220px", align: 'start'},
          {value: 'claim',   text: 'Claim Date',width: "120px", align: 'start'},
          {value: 'type',    text: 'Type',      width: "120px", align: 'start'},
          {value: 'status',  text: 'Status',    width: "120px", align: 'start'},
          {value: 'damages', text: 'Damages',   width: "120px", align: 'end'},
          {value: 'defense', text: 'Defense',   width: "120px", align: 'end'},
          {value: 'total_remaining_reserves', text: 'Total Remaining Reserves', width: "220px",
            align: 'end'},
          {value: 'total_indemnity',text: 'Total Indemnity',width: "220px", align: 'end'},
          {value: 'total_defense',  text: 'Total Defense',  width: "220px", align: 'end'},
          {value: 'total_fkb',      text: 'Total FKB',      width: "220px", align: 'end'},
          {value: 'total_incurred', text: 'Total Incurred', width: "220px", align: 'end'},
        ],
      }
    },

    created () {
      this.getClaimsForThisFirm(this.firm.fid);
    },

    methods: {
      editClaim (item) {
        if (this.rightsArray.includes('edit-claim')) {//can open popup to edit note/email page if user has right
        let baseUrl = window.location.origin;
        window.open(baseUrl +  '/editClaim/' +  item.clid, 'View App '
          + item.clid, 'resizeable=yes, width=' + (800) +
          ', height=' + (700));
        }
      },

      viewClaim (item) {
        let baseUrl = window.location.origin;
        window.open(baseUrl +  '/viewClaim/' +  item.clid, 'View App '
          + item.clid, 'resizeable=yes, width=' + (900) +
          ', height=' + (700));
      },

      save(item) {
        // make deep copy to change date format of, not original,
        // so datepicker won't receive invalid date format
        let saveItem = JSON.parse(JSON.stringify(item));
        //first check date for null value and allow that to be sent back
        if (saveItem.claim == null || saveItem.claim === '' || saveItem.claim.newDate ) {
            saveItem.claim = null;
        } else {
          // if date is valid, check if format is valid for api and if not, convert to correct format
          if (moment(saveItem.claim).isValid()) {
            if (!moment(saveItem.claim, 'DD MMM YYYY', true).isValid()) {
              saveItem.claim = moment(saveItem.claim, 'YYYY-MM-DD').format('DD MMM YYYY');
            }
          } else {// delete the date if format is not acceptable
            delete saveItem.claim;
          }
        }
        return claimAPIService.updateClaim(saveItem.clid, saveItem, this.$router)
          .then((response) => {
            this.getClaimsForThisFirm(item.fid);
          })
          .catch((error) => {
            this.errors.push(error);
            this.showDatePicker = false;
          });
      },

      getClaimsForThisFirm(id) {
        this.loading = true;
        let FID = id.toString();
        apiService.getFirmClaimTotals(FID, this.$router).then((data) => {
          this.loading = false;
          this.claims = data;
          // console.log('this.claims', this.claims);
          for (let i = 0; i < this.claims.length; i += 1) {
            this.claims[i].noDate = false;
            // console.log('this.claims[i].claim', this.claims[i].claim);
            if (!this.claims[i].claim) {
              //if no date in this field set date to today for datepicker but not for text-field
              this.claims[i].noDate = true;
              this.claims[i].claim = new Date().toISOString().substr(0, 10);
            } else {
              this.claims[i].claim=moment(this.claims[i].claim,'DD MMM YYYY').format('YYYY-MM-DD');
            }
          }
          this.showDatePicker = false;
        })
          .catch((error) => {
            this.errors.push(error);
            if (error.response && error.response.status === 401) {
              this.$router.push({
                name: 'Login'
              });
            }
          });
      },
    },

  }
</script>

<style>
  .left .v-data-table__empty-wrapper {
    text-align: left !important;
  }

</style>

