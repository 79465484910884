<template>
  <div>
    <v-card :key="$_sideMenu" eager style="border-top: 1px solid white !important;"
            class="px-0 pt-0 my-1 d-flex flex-row justify-space-around"
            color="primary" v-sticky sticky-z-index="9" sticky-offset="$_offset" tile
            large dark block>
      <div><v-btn class="px-1 my-n1" x-large tile text @click="save">Save Member</v-btn></div>
    </v-card>
    <v-row dense class="ma-1" justify="center">
      <v-col cols="12" sm="4">
        <v-card class="px-2">
          <v-card-title class="pt-1 pb-3"> Id </v-card-title>
          <v-card-text class="mb-n8 pb-0">
            <v-text-field v-model="member.given" dense
                          spellcheck="false" label="Given"></v-text-field>
            <v-text-field v-model="member.surname" dense
                          spellcheck="false" label="Surname"></v-text-field>
            <v-text-field v-model="member.salutation" dense
                          spellcheck="false" label="Salutation"></v-text-field>
            <v-text-field v-model="member.ssn" dense
                          spellcheck="false" label="SSN"></v-text-field>
            <v-text-field v-model="member.suffix" dense
                          spellcheck="false" label="Suffix"></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="4">
        <v-card class="px-2">
          <v-card-title class="pt-1 pb-3"> Phone </v-card-title>
          <v-card-text class="mb-n8 pb-0">
            <v-text-field dense v-mask="'(###) ###-####'" v-model="member.mobile" label="Mobile"></v-text-field>
            <v-text-field dense v-mask="'(###) ###-####'" v-model="member.home" label="Home"></v-text-field>
            <v-text-field dense v-mask="'(###) ###-####'" v-model="member.direct" label="Direct"></v-text-field>
            <v-text-field dense v-mask="'##########'" v-model="member.ext" label="Ext"></v-text-field>
            <v-text-field dense v-mask="'(###) ###-####'" v-model="member.fax" label="Fax"></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="12" sm="4">
        <v-card class="px-2">
          <v-card-title class="pt-1 pb-3"> HR </v-card-title>
          <v-card-text class="mb-n8 pb-0">
            <v-text-field v-model="member.licensed" dense
                          spellcheck="false" label="Licensed"></v-text-field>
            <v-text-field v-model="member.hired" dense
                          spellcheck="false" label="Hired"></v-text-field>
            <v-text-field v-model="member.role" dense
                          spellcheck="false" label="Role"></v-text-field>
            <v-text-field v-model="member.hours" dense
                          spellcheck="false" label="Hours"></v-text-field>
            <v-text-field v-model="member.ce" dense
                          spellcheck="false" label="CE"></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>

    <v-row dense class="mx-1 mt-9" justify="center">
      <v-col cols="12" sm="12">
        <v-card class="px-2">
          <v-card-text class="pb-0">
            <v-text-field v-model="member.email" dense
                          spellcheck="false" label="Email"></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>

    </v-row>

    <br>
    <v-snackbar app color="secondary" v-model="successSnackbar" style="top: 10px;" top>
      Member changes have been saved.
      <v-btn class="ml-12 pl-12" color="white" text @click="successSnackbar = false">Close</v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import ListViewer from '@/components/ListViewer';
import loginCheckMixin from '@/mixin';
import { PeopleAPIService } from "@/servicehandlers/PeopleAPIService";

const peopleAPIService = new PeopleAPIService();

export default {
  name: "EditMember",
  mixins: [loginCheckMixin],
  components: { ListViewer },
  props: {
    id: [String, Number],
    default() {
      return {};
    }
  },
  data() {
    return {
      member: {},
      rightsArray: [],
      successSnackbar: false,
    };
  },

  created() {
    this.rightsArray = this.loginCheckMixin(['page-people', 'edit-people']);
    console.debug('in editMember w id', this.id);
    peopleAPIService.getPerson(this.id, this.$router)
      .then((member) => {
        console.debug('member', member);
        this.member = member;
        document.title = 'Edit member ' + this.member.fid;
        // format phone numbers
        this.member.mobile = this.member.mobile.replace(/(\d{3})(\d{3})(\d{4})/,'($1) $2-$3');
        this.member.home = this.member.home.replace(/(\d{3})(\d{3})(\d{4})/,'($1) $2-$3');
        this.member.direct = this.member.direct.replace(/(\d{3})(\d{3})(\d{4})/,'($1) $2-$3');
        this.member.fax = this.member.phone.replace(/(\d{3})(\d{3})(\d{4})/,'($1) $2-$3');
      })
  },

  methods: {
    save() {
      let memberToSave =  _.cloneDeep(this.member);
      //set all undefined properties to null, or they won't be emptied
      Object.entries(memberToSave).forEach(([key, value]) => {
        if (value === undefined) memberToSave[key] = null;
      });
      if (memberToSave.mobile) memberToSave.mobile = memberToSave.mobile.replace(/[^\d]/g, '');
      if (memberToSave.home) memberToSave.home = memberToSave.home.replace(/[^\d]/g, '');
      if (memberToSave.direct) memberToSave.direct = memberToSave.direct.replace(/[^\d]/g, '');
      if (memberToSave.ext) memberToSave.ext = memberToSave.ext.replace(/[^\d]/g, '');
      if (memberToSave.fax) memberToSave.fax = memberToSave.fax.replace(/[^\d]/g, '');
      console.debug('memberToSave', memberToSave);
      return peopleAPIService.updatePerson(memberToSave.plid, memberToSave, this.$router)
        .then((data) => {
          this.successSnackbar = true;
        })
        .catch((error) => {
          console.debug('ERROR: ', error);
        });
    },
  }
};
</script>
<style>
</style>
