import CommonAPIService from './CommonHandler';
import _ from 'lodash';

export class ClaimsAPIService {
  constructor() {
    this.CommonAPIService = new CommonAPIService();
  }

  getSingleClaim(claimId, router) {
    const url = `/api/claims/${claimId}`;
    return this.CommonAPIService.getCall(url, router);
  }

  getClaim(search, router) {
    const url = '/api/claims/search';
    return this.CommonAPIService.postCall(url, search, router);
  }

  updateClaim(id, claim, router) {
    const url = `/api/claims/${id}`;
    claim = _.omit(claim, ['firm', 'files', 'factsHtml', 'noteHtml', 'updates']);
    return this.CommonAPIService.putCall(url, claim, router);
  }

  getClaimAudits(id, router) {
    const orders = 'id,user_id';
    const directions = 'DESC,DESC';
    const limit = '';    // '' returns all logs
    const url = `/api/claims/${id}/logs?orders=${orders}&directions=${directions}&limit=${limit}`
    return this.CommonAPIService.getCall(url, router);
  }

  getClaimLedgers(claimId, router) {
    const fields = '*';
    const url = `/api/claims/${claimId}/ledger?fields=${fields}`;
    return this.CommonAPIService.getCall(url, router);
  }

  addClaimLedger(body, claimId, router) {
    const url = `/api/claims/${claimId}/ledger`;
    return this.CommonAPIService.postCall(url, body, router);
  }

  updateClaimLedger(claimId, itemId, body, router) {
    const url = `/api/claims/${claimId}/ledger/${itemId}`;
    return this.CommonAPIService.putCall(url, body, router);
  }

  deleteClaimLedger(claimId, itemId, router) {
    const url = `/api/claims/${claimId}/ledger/${itemId}`;
    return this.CommonAPIService.deleteCall(url, router);
  }
}

export default ClaimsAPIService;
