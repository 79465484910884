<template>
  <div>
    <component
        :attach="attach"
        :autofocus="autofocus"
        @blur="focused = false"
        :chips="chips"
        @click:prepend="$emit('click-prepend')"
        @click:append="appendClick"
        @click:append-outer="appendOuterClick"
        :color="color"
        :custom-select="customSelect"
        :custom-select-field="customSelectField"
        :deletable-chips="deletableChips"
        :dense="dense"
        :disabled="disabled"
        @focus="focused = true"
        @input="$emit('input', $event === '' && allowStr ? null : $event)"
        :is="type"
        :items="items"
        :item-text="itemText"
        :item-value="itemValue"
        @keydown.enter.prevent="enterPress"
        :label="label" @click="raiseClickEvent()"
        :menu-props="menuProps"
        :multiple="multiple"
        :no-data-text="noDataText"
        :readonly="readonly"
        ref="field"
        :return-object="returnObject"
        :rows="rows"
        :rules="rules"
        :small-chips="smallChips"
        :tabindex="tabindex"
        :value="value"
        v-bind="{...inputProps, appendIcon, appendOuterIcon}"
    >
      <template v-slot:prepend v-if="!!prependIcon">
        <span style="margin-top: -6px">
          <button-tip btnclass="ml-n4 mr-n3" @click="$emit('click-prepend')" icon :iname="prependIcon"
                      :tip="prependTip"/>
        </span>
      </template>
      <template v-slot:append-outer v-if="dirtyCheck && dirty && revertTip">
        <span style="margin-top: -6px">
          <button-tip btnclass="ml-n4 mr-n3" @click="appendOuterClick()" icon iname="mdi-arrow-u-left-top"
                      tip="Revert Value"/>
        </span>
      </template>
      <template v-if="customSelect" v-slot:item="{ item }">
        <v-tooltip :nudge-left="35" right>
          <template v-slot:activator="{ on }">
            <v-list-item-title v-on="on">{{ item.value }}</v-list-item-title>
          </template>
          {{ item[customSelectField] }}
        </v-tooltip>
      </template>
    </component>
  </div>
</template>

<script>
  import { VAutocomplete, VCombobox, VSelect, VTextarea, VTextField } from "vuetify/lib"

  export default {
    name: "SmartInput",
    components: { VAutocomplete, VCombobox, VSelect, VTextarea, VTextField },
    props: {
      allowStr: { type: Boolean, default: false },// set to true if str val shouldn't trigger dirty check (allow "1"=1)
      attach: { type: Boolean, default: false },
      autofocus: { type: Boolean, default: false },
      chips: { type: Boolean, default: false },
      cleanValue: { default: null },
      clearable: { type: Boolean, default: false },
      clearValue: { default: null },
      color: { type: String, default: '' },
      customSelect: { type: Boolean, default: false },
      customSelectField: String,
      deletableChips: { type: Boolean, default: false },
      dense: { type: Boolean, default: false },
      didScroll: { type: Boolean, default: false },
      dirtyCheck: { type: Boolean, default: false },
      disabled: { type: Boolean, default: false },
      inputProps: { type: Object, default: () => ({}) },
      items: { type: Array, default: () => ([]) },
      itemText: {},
      itemValue: {},
      label: { type: String, default: '' },
      menuProps: { type: Object },
      multiple: { type: Boolean, default: false },
      noDataText: { type: String },
      prependIcon: { default: null },
      prependTip: { type: String, default: null },
      readonly: { type: Boolean, default: false },
      returnObject: { type: Boolean, default: false },
      revertTip: { type: Boolean, default: false },
      rows: { default: null },
      rules: { default: () => [] },
      smallChips: { type: Boolean, default: false },
      stop: { type: Boolean, default: false },
      tabindex: { type: String },// Vuetify clearable can prevent tab working as user expects. To fix set :tabindex="1".
      type: { type: String, default: 'vTextField'},
      value: { required: true }
    },
    data: () => ({
      focused: false,
    }),

    computed: {
      appendIcon() {
        if (this.inputProps.appendIcon) return this.inputProps.appendIcon;
        if (this.clearable && this.focused && !this.readonly) return 'mdi-close-circle';
      },
      appendOuterIcon() {
        if (this.inputProps.appendOuterIcon) return this.inputProps.appendOuterIcon;
        if (this.dirtyCheck && this.dirty) return 'mdi-arrow-u-left-top';
      },
      dirty() {
        // allowStr allows looser comparison  1 = "1", 0 = '0', 5.23 = '5.23', '' = null, etc. (but not 0 = '0.00', etc)
        if (this.allowStr && this.$_eq(this.value, this.cleanValue)) return false;
        return this.dirtyCheck ? !(this.value === this.cleanValue) : false;
      }

    },

    watch: {
      didScroll() {
        this.$refs.field.isMenuActive = false;// close menu if parent didScroll
      },
    },
    methods: {
      appendClick() {
        if (this.clearable && this.focused) this.clear();
      },
      appendOuterClick() {
        if (this.dirtyCheck && this.dirty) this.revert();
      },
      clear() {
        if (!this.readonly && !this.disabled) this.$emit('input', this.clearValue);
      },
      enterPress() {
        this.$refs.field.isMenuActive = false;  // close item dropdown list when enter pressed
        this.$emit('enter')
      },
      raiseClickEvent() {
        if (this.stop) event.stopPropagation()// stop propagation if stop === true
        this.$emit('click');
      },
      revert() {
        this.$emit('input', this.cleanValue);
      }
    }

  }
</script>

<style scoped>
</style>