<template>
  <div>
    <v-card class="mt-n3 rounded-0">
      <v-card-title>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search"
                      clearable single-line hide-details class="my-n2"></v-text-field>
<!--        <button-tip v-can="'firm-edit'" color="secondary" xsmall fab icolor="white" iname="mdi-plus"-->
<!--                    @click="createNewMember(firm.fid)" tip="Create Member"/>-->
      </v-card-title>
    </v-card>
<!--    <div :key="refresh">-->
      <v-data-table class="elevation-2"
                    :class="[!notPopup ? 'popupLargeTableNoFooter' : 'largeSizeTableNoFooter']"
                    dense
                    fixed-header
                    :headers="colHeaders"
                    hide-default-footer
                    item-key="plid"
                    :items="members"
                    :items-per-page="-1"
                    :loading="loading"
                    loading-text="Data loading... Please wait..."
                    :mobile-breakpoint="0"
                    :search="search"
                    sort-by="sort"
                    :style="$_cssVars"
      >
        <template v-slot:header.data-table-select>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                Inline
              </span>
            </template>
            Edit Checked Member Inline
          </v-tooltip>
        </template>


        <template v-slot:header.actions="{ header }" v-if="rights.includes('edit-people')">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                Popup
              </span>
            </template>
            Edit Member in Popup Window
          </v-tooltip>
        </template>

        <template v-slot:item.actions="{ item }" v-if="rights.includes('edit-people')">
              <v-icon class="mr-4" @click="editMember(item)">mdi-pencil</v-icon>
        </template>

        <template v-for="field in fields" v-slot:[`item.${field}`]="props">
                <div v-if="props.item[field] && field === 'sort'">
                  {{ props.item.name}}
                </div>
                <div v-else-if="props.item[field] && field === 'ssn'">
                  {{ props.item[field].replace(/(\d{3})(\d{2})(\d{4})/,'$1-$2-$3') }}
                </div>
                <div v-else-if="props.item[field] && (field === 'mobile' || field === 'direct'
                || field === 'fax' || field === 'home')">
                  {{ props.item[field].replace(/(\d{3})(\d{3})(\d{4})/,'($1) $2-$3') }}
                </div>
                <div v-else>
                  {{ props.item[field] }}
                </div>
        </template>
      </v-data-table>
<!--    </div>-->

  </div>
</template>

<script>
  import moment from 'moment';
  import ActionDialog from "../../ActionDialog";
  import { PeopleAPIService } from "@/servicehandlers/PeopleAPIService";
  import { FirmsAPIService } from "@/servicehandlers/FirmsAPIService";

  const peopleAPIService = new PeopleAPIService();
  const firmsAPIService = new FirmsAPIService();

  export default {
    name: "FirmMembersTable",
    components: {ActionDialog},
    props: {
      firm: Object,
      rightsArray: Array,
      notPopup: Boolean,
      default() {
        return {};
      }
    },
    data() {
      return {
        colHeaders: [
          {value: 'sort', text: 'Name', width: "300px",sortable: true, align: 'start'},
          {value: 'role', text: 'Role', width: "100px", sortable: true, align: 'start' },
          {value: 'mobile', text: 'Mobile', width: "140px", sortable: true, align: 'start' },
          {value: 'home', text: 'Home', width: "140px", sortable: true, align: 'start' },
          {value: 'direct', text: 'Direct', width: "140px", sortable: true, align: 'start' },
          {value: 'ext', text: 'Ext', width: "100px", sortable: true, align: 'start' },
          {value: 'fax', text: 'Fax', width: "140px", sortable: true, align: 'start' },
          {value: 'email', text: 'Email', width: "260px", sortable: true, align: 'start' },
          {value: 'licensed', text: 'Licensed', width: "120px", sortable: true, align: 'start'},
          {value: 'ce', text: 'CE', width: "90px", sortable: true, align: 'start' },
          {value: 'hired', text: 'Hired', width: "120px", sortable: true, align: 'start' },
          {value: 'hours', text: 'Hours', width: "90px", sortable: true, align: 'start' },
        ],
        colHeadersActions: {value: 'actions',text: 'Edit',width: "130px",sortable: false, align:'center'},
        errors: [],
        loading:false,
        members: [],
        // refresh: false,
        rights: [],
        saveMember: [],
        search: '',
        selectedMember: [],
        showDatePicker: false,
        singleSelect: true,
      }
    },

    created () {
      // this.rights = this.rightsArray; // add in when editing is developed
      this.getMembersForThisFirm(this.firm.fid);
      if (this.rights.includes('edit-people')) this.colHeaders.unshift(this.colHeadersActions);
    },

  computed: {
      fields() {
        return this.colHeaders.map(a => a.value === 'actions' ? '' : a.value);
      },
    },

    methods: {
      createNewMember(fid) {
        const params = {fid: fid, closer: this.username, staff: this.username};
        peopleAPIService.createPerson(params, this.$router).then((member) => {
            this.editMember(member);
          // this.refresh(); // refresh status and notes table to show new
          }).catch((e) => {
            this.$refs.errorDialog.showError({
              errorMessage: "Failed to create new member.",
              forDevelopers: e
            })
          });
      },

      editMember(member) {
        if (!member.plid) return;
        let baseUrl = window.location.origin;
        window.open(baseUrl +  '/editMember/' +  member.plid, '_blank',
          'resizeable=yes, width=' + (900)
          + ', left=' + (500)
          + ', top=' + (22)
          + ', height=' + (490));
      },

      getMembersForThisFirm(id) {
        let FID = id.toString();
        firmsAPIService.getFirmPeople(FID, this.$router).then((data) => {
          if (data) {
            this.members = data;
            for (let member of this.members) {
              let salutation = '';
              let suffix = '';
              if (member.suffix) {
                suffix = member.suffix.substring(0,2) === ', ' ? member.suffix : ', ' + member.suffix;
              }
              if (member.salutation) salutation = '(' + member.salutation + ')';
              member.name = `${member.given} ${member.surname}${suffix} ${salutation}`;
              member.sort = `${member.surname} ${member.given}`;
            }
          }
          this.showDatePicker = false;
        })
          .catch((error) => {
            this.errors.push(error);
            if (error.response && error.response.status === 401) {
              this.$router.push({
                name: 'Login'
              });
            }
          });
      },
  },

}
</script>

<style scoped>

  .theme--light.v-data-table tbody tr:not(:last-child) td:last-child, .theme--light.v-data-table tbody tr:not(:last-child) th:last-child {
    padding: 0px;
  }

</style>
